import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import UserContext from "../UserContext";
import { Link, Redirect } from "react-router-dom";

function Register({ user }) {
  const roleOptions = [
    { key: "teacher", value: "teacher", text: "Учител" },
    { key: "student", value: "student", text: "Ученик" },
    { key: "parent", value: "parent", text: "Родител" },
    { key: "buisness", value: "buisness", text: "Бизнес представител" },
  ];
  const { signUp } = useContext(UserContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  if (user) return <Redirect to="/" />;

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Създаване на нов акаунт
        </Header>
        <Form size="large">
          <Segment>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Име"
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Фамилия"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail адрес"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Парола"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Form.Select
              placeholder="Роля"
              options={roleOptions}
              onChange={(e, { value }) => setRole(value?.toString())}
              required
            />
            <Button
              disabled={!firstName || !lastName || !email || !password || !role}
              color="teal"
              fluid
              size="large"
              onClick={() => signUp(email, password, firstName, lastName, role)}
            >
              Регистрация
            </Button>
          </Segment>
        </Form>
        <Message>
          Вече имате създаден акаунт? <Link to="/login">Вход</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default Register;
