import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import UserContext from "../UserContext";
import { Link, Redirect } from "react-router-dom";

function Login({ user }) {
  const { signIn, loginError } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (user) return <Redirect to="/" />;

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Влезте във вашия акаунт
        </Header>
        <Form size="large">
          <Segment>
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail адрес"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Парола"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link to="/forgot-password">Забравена парола ?</Link>
            <Button
            style={{marginTop: "10px"}}
              disabled={!email || !password}
              color="teal"
              fluid
              size="large"
              onClick={() => signIn(email, password)}
            >
              Вход
            </Button>
            {loginError && (
              <p style={{ marginTop: "1rem", color: "red" }}>
                Грешно въведени данни. Моля, опитайте отново.
              </p>
            )}
          </Segment>
        </Form>
        <Message>
          Нов потребител ? <Link to="/register">Регистрация</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default Login;
