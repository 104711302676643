import React, { createContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { db, storage } from "./firebase";
import firebase from "firebase";

const PostsContext = createContext({
  posts: [],
});

export const PostsContextProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection("posts").onSnapshot((snapshot) =>
      setPosts(
        snapshot.docs.map((doc) => {
          let postId = doc.id;
          let data = doc.data();
          return { postId, ...data };
        })
      )
    );
  }, []);

  const addPostToDB = (post, file, userName) => {
    storage
      .ref(`${userName}/${file.name}`)
      .put(file)
      .then(() =>
        storage
          .ref(userName)
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("posts")
              .add({
                ...post,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                fileUrl: url,
              })
              .then(() => console.log("post added"))
              .catch((err) => console.log(err.message));
          })
          .catch((err) => console.log(err.message))
      )
      .catch((err) => console.log(err.message));
  };

  const deletePostFromDB = (postId, postName, userName, fileName) => {
    confirmAlert({
      title: `Изтриване на публикация - ${postName}?`,
      buttons: [
        {
          label: "Да",
          onClick: () => {
            db.collection("posts")
              .doc(postId)
              .delete()
              .then(() => {
                storage
                  .ref()
                  .child(`${userName}/${fileName}`)
                  .delete()
                  .then(() => console.log("file deleted"));
                console.log("Document successfully deleted!");
              })
              .catch((error) => {
                console.error("Error removing document: ", error);
              });
          },
        },
        {
          label: "Не",
        },
      ],
    });
  };

  const addLike = (uid, postId) => {
    db.collection("posts")
      .doc(postId)
      .update({
        likes: firebase.firestore.FieldValue.arrayUnion(uid),
      });
  };

  const removeLike = (uid, postId) => {
    db.collection("posts")
      .doc(postId)
      .update({
        likes: firebase.firestore.FieldValue.arrayRemove(uid),
      });
  };

  const uploadAvatarImage = (user, userName, file) => {
    const fileExtension = file.name.split(".")[1];
    storage
      .ref(`avatars/${userName}/avatar.${fileExtension}`)
      .put(file)
      .then((res) => {
        if (res.state === "success") {
          storage
            .ref(`avatars/${userName}`)
            .child(`avatar.${fileExtension}`)
            .getDownloadURL()
            .then((url) => {
              db.collection("users").doc(user.uid).update({
                avatar: url,
              });
            });
        }
      });
  };

  const addCommentToDB = (postId, comment) => {
    db.collection("posts").doc(postId).collection("comments").add(comment);
  };

  const deleteCommentFromDB = (postId, commentId) => {
    db.collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .delete()
      .then(() => console.log("comment is deleted"))
      .catch((err) => console.log(err.message));
  };

  const addReplyToDB = (postId, commentId, reply) => {
    db.collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .update({
        replies: firebase.firestore.FieldValue.arrayUnion(reply),
      });
  };

  const deleteReplyFromDB = (postId, commentId, reply) => {
    db.collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .update({
        replies: firebase.firestore.FieldValue.arrayRemove(reply),
      });
  };

  const context = {
    posts,
    setPosts,
    addPostToDB,
    addLike,
    removeLike,
    deletePostFromDB,
    uploadAvatarImage,
    addCommentToDB,
    deleteCommentFromDB,
    addReplyToDB,
    deleteReplyFromDB,
  };

  return (
    <PostsContext.Provider value={context}>{children}</PostsContext.Provider>
  );
};

export default PostsContext;
