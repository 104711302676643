import React from "react";
import { Grid, List, Segment } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import User from "./User";

function Users({ user, userDetails, users }) {
  if (!user) {
    return <Redirect to="/" />;
  } else if (userDetails && userDetails.role !== "admin") {
    return <Redirect to="/" />;
  }

  return (
    <Segment>
      <List>
        <Grid columns={5} divided>
          {users &&
            users.map((user) => (
              <Grid.Column key={user.userId} textAlign="center">
                <User
                  userId={user.userId}
                  userName={user.name}
                  userEmail={user.email}
                  userRole={user.role}
                  userAvatar={user.avatar}
                />
              </Grid.Column>
            ))}
        </Grid>
      </List>
    </Segment>
  );
}

export default Users;
