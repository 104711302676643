import React from "react";
import { Container, Image } from "semantic-ui-react";
import bgImage from "../img/innovation-561388_1920.jpg";

function Home() {
  return (
    <Container>
      <h1 style={{ textAlign: "center" }}>
        Добре дошли в нашия "Бизнес Инкубатор"!
      </h1>
      <p style={{ textAlign: "justify" }}>
        Уважаеми ученици, това е мястото, където можете да споделяте и развивате
        своите бизнес идеи, които биха могли в бъдеще да бъдат бизнес
        възможности. Тук можете да търсите съмишленици, да намерите подкрепа и
        съвет от представители на бизнеса.
      </p>
      <p style={{ textAlign: "justify" }}>
        Уважаеми представители на бизнеса, това е една прекрасна възможност за
        Вас да инвестирате своя професионален опит и бизнес нюх в иновативни и
        креативни идеи!
      </p>
      <p style={{ textAlign: "justify" }}>
        Уважаеми преподаватели, подкрепете Своите ученици в техните първи опити
        да изградят успешни бизнес модели!
      </p>
      <p style={{ textAlign: "justify" }}>
        Уважаеми родители, бъдете до децата си и вярвайте в силата на техния
        предприемачески дух!
      </p>
      <Image src={bgImage} alt="bg-image" rounded size="huge" centered style={{boxShadow: '0px 0px 5px black'}} />
    </Container>
  );
}

export default Home;
