import React from "react";
import { Comment } from "semantic-ui-react";
import moment from "moment";
import "moment/locale/bg";

function CommentReplies({
  comment,
  created,
  user,
  userAvatar,
  userId,
  currentUserId,
  deleteReplyFromDB,
  postId,
  commentId,
}) {
  const deleteReply = () => {
    const replyObject = {
      comment,
      created,
      user,
      userAvatar,
      userId,
    };
    deleteReplyFromDB(postId, commentId, replyObject);
  };
  return (
    <Comment.Group threaded>
      <Comment>
        <Comment.Avatar src={userAvatar} />
        <Comment.Content>
          <Comment.Author as="a">{user}</Comment.Author>
          <Comment.Metadata>
            <div>{moment(created?.toDate()).fromNow()}</div>
          </Comment.Metadata>
          <Comment.Text>{comment}</Comment.Text>
          {currentUserId === userId && (
            <Comment.Actions>
              <Comment.Action onClick={deleteReply}>Изтрии</Comment.Action>
            </Comment.Actions>
          )}
        </Comment.Content>
      </Comment>
    </Comment.Group>
  );
}

export default CommentReplies;
