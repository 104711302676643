import React from "react";
import { Button, Grid, Icon, Card } from "semantic-ui-react";

function ProfilePost({ post, deletePostFromDB, user }) {
  return (
    <Grid.Column>
      <Card>
        <Card.Content>
          <Button
            onClick={() =>
              deletePostFromDB(
                post.postId,
                post.title,
                user.displayName,
                post.file
              )
            }
            icon
            floated="right"
            color="red"
            compact
          >
            <Icon name="trash alternate" />
          </Button>

          <Card.Header>{post.title}</Card.Header>
          <Card.Meta>{post.timeStamp}</Card.Meta>
          <Card.Description>
            <a href={post.fileUrl}>{post.file}</a>
          </Card.Description>
        </Card.Content>
        <Card.Content extra className="extraIcons">
          <Icon name="heart" />
          {post.likes.length}
        </Card.Content>
      </Card>
    </Grid.Column>
  );
}

export default ProfilePost;
