import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import UserContext from "../UserContext";
import { Redirect } from "react-router-dom";

function ForgotPassword({ user }) {
  const { resetEmailMessage, resetPassword } = useContext(UserContext);

  const [email, setEmail] = useState("");

  if (user) return <Redirect to="/" />;

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Възстановяване на парола
        </Header>
        <Form size="large">
          <Segment>
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail адрес"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              disabled={!email}
              color="teal"
              fluid
              size="large"
              onClick={() => resetPassword(email)}
            >
              Изпрати парола на Email
            </Button>
          </Segment>
        </Form>
        {resetEmailMessage && <Message style={{color:"green"}}>{resetEmailMessage}</Message>}
      </Grid.Column>
    </Grid>
  );
}

export default ForgotPassword;
