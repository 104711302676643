import React, { useEffect, useState } from "react";
import { Comment, Icon, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/bg";
import { db } from "../../firebase";
import CommentReplies from "./CommentReplies";
import firebase from "firebase";

function PostComment({
  commentId,
  currentUser,
  currentUserAvatar,
  currentUserId,
  commentUserId,
  user,
  created,
  comment,
  avatar,
  deleteCommentFromDB,
  postId,
  addReplyToDB,
  deleteReplyFromDB,
}) {
  const [replies, setReplies] = useState([]);
  const [reply, setReply] = useState("");
  const [replyShow, setReplyShow] = useState(false);

  useEffect(() => {
    db.collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId)
      .onSnapshot((snapshot) => setReplies(snapshot.data()?.replies));
  }, [postId, commentId]);

  const handleReply = (e) => {
    setReply(e.target.value);
  };

  const addReply = (e) => {
    e.preventDefault();
    const replyObject = {
      comment: reply,
      created: firebase.firestore.Timestamp.now(),
      user: currentUser,
      userAvatar: currentUserAvatar,
      userId: currentUserId,
    };
    addReplyToDB(postId, commentId, replyObject);
    setReply("");
  };

  return (
    <Comment>
      <Comment.Avatar as={Link} to={`/user/${commentUserId}`} src={avatar} />
      <Comment.Content>
        <Comment.Author as={Link} to={`/user/${commentUserId}`}>
          {user}
        </Comment.Author>
        <Comment.Metadata>
          <div>{moment(created?.toDate()).fromNow()}</div>
        </Comment.Metadata>
        <Comment.Text>{comment}</Comment.Text>
        <Comment.Actions>
          <Comment.Action onClick={() => setReplyShow(!replyShow)}>
            Отговор
          </Comment.Action>
          {currentUserId === commentUserId && (
            <Comment.Action
              onClick={() => deleteCommentFromDB(postId, commentId)}
            >
              Изтрии
            </Comment.Action>
          )}
        </Comment.Actions>
        {replyShow && (
          <form onSubmit={addReply}>
            <Input
              icon={
                <button
                  style={{
                    border: "none",
                    background: "none",
                    marginLeft: "-35px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  <Icon name="send" />
                </button>
              }
              value={reply}
              label={user}
              onChange={handleReply}
            />
          </form>
        )}
      </Comment.Content>

      {replies &&
        replies.map((reply, index) => (
          <CommentReplies
            key={index}
            currentUserId={currentUserId}
            comment={reply.comment}
            created={reply.created}
            user={reply.user}
            userAvatar={reply.userAvatar}
            userId={reply.userId}
            deleteReplyFromDB={deleteReplyFromDB}
            postId={postId}
            commentId={commentId}
          />
        ))}
    </Comment>
  );
}

export default PostComment;
