import React, { createRef, useState, useContext } from "react";
import { Button, Modal, Input, Icon } from "semantic-ui-react";
import PostsContext from "../../PostsContext";
import UserContext from "../../UserContext";

function AddPostModal({ openModal, setOpenModal }) {
  const { addPostToDB } = useContext(PostsContext);
  const { user, userDetails } = useContext(UserContext);
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");

  const fileInputRef = createRef();

  const fileChange = () => {
    if (fileInputRef.current.files[0]) {
      setFile(fileInputRef.current.files[0]);
    }
  };

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const clearFile = () => {
    fileInputRef.current.value = null;
    setFile("");
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    let date = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let fullDate = `${date}.${month}.${year} в ${hours}:${
      minutes <= 9 ? "0" + minutes : minutes
    } ч. `;
    return fullDate;
  };

  const addPost = (e) => {
    e.preventDefault();
    const post = {
      authorId: user.uid,
      author: user.displayName,
      title,
      file: file.name,
      avatar: userDetails.avatar,
      likes: [],
      timeStamp: getCurrentDate(),
    };
    addPostToDB(post, file, user.displayName);
    setFile("");
    fileInputRef.current.value = null;
    setOpenModal(false);
  };

  return (
    <Modal
      dimmer="blurring"
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      open={openModal}
    >
      <Modal.Header>Нова публикация</Modal.Header>
      <Modal.Content>
        <div>
          <Input
            fluid
            label="Заглавие на публикацията"
            onChange={titleChange}
          />
        </div>
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Button
            content="Избор на файл"
            labelPosition="left"
            icon="file"
            onClick={() => fileInputRef.current.click()}
          />
          <input ref={fileInputRef} type="file" hidden onChange={fileChange} />
          {file && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "5px",
                marginTop: "10px",
              }}
            >
              {file.name}
              <Button
                onClick={clearFile}
                negative
                icon
                compact
                style={{ marginLeft: "1rem" }}
              >
                <Icon name="delete" />
              </Button>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={addPost} disabled={!title || !file.name}>
          Добави
        </Button>
        <Button
          negative
          onClick={() => {
            setOpenModal(false);
            setFile("");
            fileInputRef.current.value = null;
          }}
        >
          Отказ
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddPostModal;
