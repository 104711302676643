import React, { useState, useContext } from "react";
import { Button, Icon, Label, Menu } from "semantic-ui-react";
import AddPostModal from "../pages/Posts/AddPostModal";
import { Link, NavLink } from "react-router-dom";
import { auth } from "../firebase";
import PostsContext from "../PostsContext";

export default function Nav({ user, userDetails }) {
  const [openModal, setOpenModal] = useState(false);
  const { posts } = useContext(PostsContext);

  const userPosts = posts.filter((post) => post?.authorId === user?.uid);

  return (
    <>
      <AddPostModal openModal={openModal} setOpenModal={setOpenModal} />
      <Menu stackable>
        <Menu.Item>
          <h3>Бизнес инкубатор</h3>
        </Menu.Item>
        <Menu.Item as={NavLink} exact to="/" name="начало" />
        {user && <Menu.Item as={NavLink} to="/posts" name="публикации" />}
        {user && <Menu.Item as={NavLink} to="/profile" name="профил" />}
        {user && userDetails && userDetails.role === "admin" && (
          <Menu.Item as={NavLink} to="/users" name="Потребители" />
        )}
        <Menu.Menu position="right">
          {user ? (
            <>
              {userPosts &&
                userPosts.map(
                  (post, index) =>
                    post.likes.length > 0 && (
                      <Menu.Item key={index}>
                        <Label style={{ margin: 0 }} color="red">
                          <Icon name="heart" />
                          {post.likes.length}
                        </Label>
                      </Menu.Item>
                    )
                )}
              <Menu.Item>
                {userDetails && (
                  <Link to="/profile" className="userDetails">
                    <img src={userDetails.avatar} alt="avatar" />
                    <p>{user.displayName}</p>
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                <Button
                  onClick={() => setOpenModal(true)}
                  color="teal"
                  animated
                >
                  <Button.Content visible>Нова публикация</Button.Content>
                  <Button.Content hidden>
                    <Icon name="add" />
                  </Button.Content>
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button onClick={() => auth.signOut()}>Изход</Button>
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item>
                <Link to="/login">
                  <Button primary>Вход</Button>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/register">
                  <Button>Регистрация</Button>
                </Link>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
    </>
  );
}
