import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import { PostsContextProvider } from "./PostsContext";
import { UserContextProvider } from "./UserContext";
import { BrowserRouter as Router } from "react-router-dom";
import { auth } from "./firebase";

auth.onAuthStateChanged(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <UserContextProvider>
          <PostsContextProvider>
            <App />
          </PostsContextProvider>
        </UserContextProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
