import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Icon, Image, Label, Popup } from "semantic-ui-react";
import PostsContext from "../../PostsContext";
import { Link } from "react-router-dom";
import { db } from "../../firebase";

function Post({
  user,
  postId,
  authorId,
  author,
  title,
  avatar,
  timestamp,
  file,
  fileUrl,
  likes,
}) {
  const { addLike, removeLike } = useContext(PostsContext);
  const [comments, setComments] = useState([]);

  const fileNameExtension = file.split(".").pop();

  useEffect(() => {
    db.collection("posts")
      .doc(postId)
      .collection("comments")
      .onSnapshot((snapshot) =>
        setComments(snapshot.docs.map((doc) => doc.data()))
      );
  }, [postId]);

  return (
    <Card fluid>
      <Card.Content>
        {avatar && (
          <Link to={`/user/${authorId}`} className="userLink">
            <Popup
              content={`Към профила на ${author}`}
              trigger={
                <Image size="mini" circular floated="right" src={avatar} />
              }
            />
          </Link>
        )}
        <Card.Header floated="left">
          {title} - <span className="meta">{author}</span>
        </Card.Header>
        <Card.Meta>{timestamp}</Card.Meta>
        <Card.Description>
          <a href={fileUrl}>
            <Button icon labelPosition="left">
              {fileNameExtension === "pdf" ? (
                <Icon name="file pdf outline" />
              ) : fileNameExtension === "doc" ? (
                <Icon name="file word outline" />
              ) : fileNameExtension === "docx" ? (
                <Icon name="file word outline" />
              ) : fileNameExtension === "docm" ? (
                <Icon name="file word outline" />
              ) : fileNameExtension === "jpg" ? (
                <Icon name="file image outline" />
              ) : fileNameExtension === "jpeg" ? (
                <Icon name="file image outline" />
              ) : fileNameExtension === "ppt" ? (
                <Icon name="file powerpoint outline" />
              ) : fileNameExtension === "pptx" ? (
                <Icon name="file powerpoint outline" />
              ) : fileNameExtension === "pptm" ? (
                <Icon name="file powerpoint outline" />
              ) : (
                <Icon name="file outline" />
              )}
              Преглед на файл
            </Button>
          </a>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button as="div" labelPosition="right">
          {!likes.includes(user.uid) ? (
            <Button
              color="red"
              icon
              basic
              id={postId}
              onClick={() => addLike(user.uid, postId)}
            >
              <Icon name="heart" />
            </Button>
          ) : (
            <Button
              color="red"
              icon
              id={postId}
              onClick={() => removeLike(user.uid, postId)}
            >
              <Icon name="heart" />
            </Button>
          )}
          <Label color="red" basic pointing="left">
            {likes.length}
          </Label>
        </Button>

        <Button as={Link} to={`/comments/${postId}`} labelPosition="right">
          <Button icon basic color="teal">
            <Icon name="comments" />
          </Button>
          <Label color="teal" basic pointing="left">
            {comments.length}
          </Label>
        </Button>
      </Card.Content>
    </Card>
  );
}

export default Post;
