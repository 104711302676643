import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Comment, Form, Header } from "semantic-ui-react";
import { db } from "../../firebase";
import PostComment from "./PostComment";
import PostsContext from "../../PostsContext";
import firebase from "firebase";

function PostComments({ match, userDetails, user }) {
  const { addCommentToDB, deleteCommentFromDB, addReplyToDB, deleteReplyFromDB } = useContext(PostsContext);
  const id = match.params.postId;
  const [comments, setComments] = useState(null);
  const [post, setPost] = useState(null);
  const [userComment, setUserComment] = useState("");

  useEffect(() => {
    db.collection("posts")
      .doc(id)
      .onSnapshot((snapshot) => {
        setPost(snapshot.data());
      });
  }, [id]);

  useEffect(() => {
    db.collection("posts")
      .doc(id)
      .collection("comments")
      .orderBy("created", "desc")
      .onSnapshot((snapshot) =>
        setComments(
          snapshot.docs.map((doc) => {
            let commentId = doc.id;
            let data = doc.data();
            return { commentId, ...data };
          })
        )
      );
  }, [id]);

  const addComment = (e) => {
    e.preventDefault();
    let commentObject = {
      comment: userComment,
      user: user.displayName,
      userId: user.uid,
      userAvatar: userDetails.avatar,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };
    addCommentToDB(id, commentObject);
    setUserComment("");
  };

  return (
    <Container fluid>
      <Comment.Group threaded>
        {post && (
          <Header as="h3" dividing>
            Коментари за {post.title}
          </Header>
        )}
        {comments && comments.length ? (
          comments.map((comment, index) => (
            <PostComment
              key={comment.commentId}
              commentId={comment.commentId}
              user={comment.user}
              currentUser={user.displayName}
              currentUserId={user.uid}
              currentUserAvatar={userDetails?.avatar}
              commentUserId={comment.userId}
              comment={comment.comment}
              created={comment.created}
              avatar={comment.userAvatar}
              deleteCommentFromDB={deleteCommentFromDB}
              postId={id}
              addReplyToDB={addReplyToDB}
              deleteReplyFromDB={deleteReplyFromDB}
            />
          ))
        ) : (
          <p style={{ color: "blue" }}>
            Добавете първия коментар за тази публикация.
          </p>
        )}
        <Form reply onSubmit={addComment}>
          <Form.TextArea
            onChange={(e) => setUserComment(e.target.value)}
            value={userComment}
          />
          <Button
            content="Добави коментар"
            labelPosition="left"
            icon="edit"
            primary
          />
        </Form>
      </Comment.Group>
    </Container>
  );
}

export default PostComments;
