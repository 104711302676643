import React, { useContext } from "react";
import { Container } from "semantic-ui-react";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserContext from "./UserContext";
import Profile from "./pages/Profile/Profile";
import Posts from "./pages/Posts/Posts";
import UserProfile from "./pages/UserProfile.js/UserProfile";
import Users from "./pages/Users/Users";
import PostComments from "./pages/Posts/PostComments";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  const { user, users, userDetails } = useContext(UserContext);

  return (
    <>
      <Nav user={user} userDetails={userDetails} />
      <Container>
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          {user && (
            <Route
              exact
              path="/posts"
              render={(props) => (
                <Posts {...props} user={user} userDetails={userDetails} />
              )}
            />
          )}
          {user && (
            <Route
              exact
              path="/comments/:postId"
              render={(props) => (
                <PostComments
                  {...props}
                  user={user}
                  userDetails={userDetails}
                />
              )}
            />
          )}
          {user && (
            <Route
              exact
              path="/profile"
              render={(props) => (
                <Profile {...props} user={user} userDetails={userDetails} />
              )}
            />
          )}
          {user && (
            <Route
              exact
              path="/user/:id"
              render={(props) => (
                <UserProfile {...props} user={user} userDetails={userDetails} />
              )}
            />
          )}
          <Route
            exact
            path="/users"
            render={(props) => (
              <Users
                {...props}
                user={user}
                users={users}
                userDetails={userDetails}
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => <Login {...props} user={user} />}
          />
          <Route
            exact
            path="/register"
            render={(props) => <Register {...props} user={user} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} user={user} />}
          />
        </Switch>
      </Container>
    </>
  );
}

export default App;
