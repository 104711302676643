import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { auth, db } from "./firebase";
import firebase from "firebase";

const UserContext = createContext({
  user: null,
  users: null,
  userDetails: null,
  loginError: null,
  resetEmailMessage: null,
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [resetEmailMessage, setResetEmailMessage] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      setUser(userAuth);
    });
  }, []);

  useEffect(() => {
    db.collection("users").onSnapshot((snapshot) =>
      setUsers(
        snapshot.docs.map((doc) => {
          let userId = doc.id;
          let data = doc.data();
          return { userId, ...data };
        })
      )
    );
  }, []);

  useEffect(() => {
    if (user) {
      db.collection("users")
        .doc(user.uid)
        .onSnapshot((snapshot) => setUserDetails(snapshot.data()));
    }
  }, [user]);

  const signUp = (email, password, firstName, lastName, role) => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        db.collection("users")
          .doc(res.user.uid)
          .set({
            name: firstName + " " + lastName,
            email,
            role,
            avatar:
              "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          });
        if (res.user) {
          res.user.updateProfile({
            displayName: firstName + " " + lastName,
          });
        }
        history.push("/");
      })
      .catch((err) => console.log(err.message));
  };

  const signIn = (email, password) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        history.push("/");
      })
      .catch((err) => setLoginError(err.message));
  };

  const resetPassword = (email) => {
    firebase.auth().languageCode = "bg";
    auth.sendPasswordResetEmail(email).then(() => {
      setResetEmailMessage(
        "Линк за възстановяване на паролата е изпратена на въведения Email."
      );
    });
  };

  const context = {
    user,
    signUp,
    signIn,
    userDetails,
    loginError,
    users,
    resetPassword,
    resetEmailMessage,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserContext;
