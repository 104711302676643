import React, { useContext } from "react";
import { Container, Grid } from "semantic-ui-react";
import Post from "./Post";
import PostsContext from "../../PostsContext";

function Posts({ user }) {
  const { posts } = useContext(PostsContext);

  if (!user)
    return (
      <Container>
        <h1>
          Трябва да сте регистриран потребител, за да преглеждате тази страница.
        </h1>
      </Container>
    );
  return (
    <Container>
      {!posts.length ? (
        <h2 style={{ textAlign: "center" }}>Липсват публикации.</h2>
      ) : (
        <>
          <h1 className="centerText">Последни публикации</h1>
          <Grid columns={3} divided="vertically" stackable>
            <Grid.Row>
              {user &&
                posts &&
                posts.map((post, index) => (
                  <Grid.Column key={index}>
                    <Post
                      user={user}
                      postId={post.postId}
                      authorId={post.authorId}
                      author={post.author}
                      title={post.title}
                      avatar={post.avatar}
                      timestamp={post.timeStamp}
                      file={post.file}
                      fileUrl={post.fileUrl}
                      likes={post.likes}
                      comments={post.comments}
                    />
                  </Grid.Column>
                ))}
            </Grid.Row>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default Posts;
