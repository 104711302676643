import React, { useEffect, useState } from "react";
import { Card, Container, Grid, Icon } from "semantic-ui-react";
import "./UserProfile.css";
import { db } from "../../firebase";

function UserProfile({ user, match }) {
  const [profilePosts, setProfilePosts] = useState([]);
  const [indUser, setIndUser] = useState(null);

  const userId = match.params.id;

  useEffect(() => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIndUser(doc.data());
        }
      });
  }, [userId]);

  useEffect(() => {
    db.collection("posts")
      .where("authorId", "==", userId)
      .onSnapshot((snapshot) =>
        setProfilePosts(snapshot.docs.map((doc) => doc.data()))
      );
  }, [userId]);

  return (
    <Container className="profileContainer">
      <div className="profileHeader"></div>
      <Grid stackable columns={2} className="profileContent">
        <Grid.Column className="user" width={6}>
          {indUser && (
            <>
              <img src={indUser.avatar} alt="avatar" className="avatar" />
              <div>
                <h4>{indUser.name}</h4>
                <p>
                  {indUser.role === "admin"
                    ? "Администратор"
                    : indUser.role === "teacher"
                    ? "Учител"
                    : indUser.role === "parent"
                    ? "Родител"
                    : indUser.role === "buisness"
                    ? "Бизнес представител"
                    : indUser.role === "student"
                    ? "Ученик"
                    : null}
                </p>
                {profilePosts && <p>{profilePosts.length} публикации</p>}
              </div>
            </>
          )}
        </Grid.Column>
        {profilePosts && profilePosts.length > 0 ? (
          <Grid.Column className="userPosts" stackable="true" width={10}>
            <h3>Публикации:</h3>
            <Grid columns={2} stackable>
              {profilePosts.map((post, index) => (
                <Grid.Column key={index}>
                  <Card>
                    <Card.Content>
                      <Card.Header>{post.title}</Card.Header>
                      <Card.Meta>{post.timeStamp}</Card.Meta>
                      <Card.Description>
                        <a href={post.fileUrl}>{post.file}</a>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="heart" />
                      {post.likes.length}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              ))}
            </Grid>
          </Grid.Column>
        ) : (
          !profilePosts.length && <h3>Липсват публикации.</h3>
        )}
      </Grid>
    </Container>
  );
}

export default UserProfile;
