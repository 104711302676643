import React from "react";
import { Link } from "react-router-dom";
import { List, Image } from "semantic-ui-react";

function User({ userName, userEmail, userRole, userAvatar, userId }) {
  return (
    <List.Item>
      <Image avatar src={userAvatar} alt="avatar" />
      <List.Content>
        <List.Header>
          <Link to={`/user/${userId}`}>{userName}</Link> -{" "}
          <b>
            {userRole === "admin"
              ? "Администратор"
              : userRole === "teacher"
              ? "Учител"
              : userRole === "parent"
              ? "Родител"
              : userRole === "buisness"
              ? "Бизнес представител"
              : userRole === "student"
              ? "Ученик"
              : null}
          </b>
        </List.Header>
        <List.Description>{userEmail}</List.Description>
      </List.Content>
    </List.Item>
  );
}

export default User;
