import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Grid, Popup } from "semantic-ui-react";
import PostsContext from "../../PostsContext";
import ProfilePost from "./ProfilePost";
import { Redirect } from "react-router-dom";
import "./Profile.css";

function Profile({ user, userDetails }) {
  const { posts, deletePostFromDB, uploadAvatarImage } = useContext(
    PostsContext
  );
  const [profilePosts, setProfilePosts] = useState([]);

  const avatarFile = useRef(null);

  useEffect(() => {
    if (user && posts) {
      const userPosts = posts.filter((post) => post.authorId === user.uid);
      setProfilePosts(userPosts);
    }
  }, [user, posts]);

  if (!user) return <Redirect to="/" />;

  return (
    <Container className="profileContainer">
      <div className="profileHeader"></div>
      <Grid stackable columns={2} className="profileContent">
        <Grid.Column className="userInfo" width={6}>
          <Popup
            basic
            content="Промяна на профилна снимка"
            position="top center"
            trigger={
              <div className="avatarContainer">
                <input
                  type="file"
                  accept="image/*"
                  ref={avatarFile}
                  style={{ display: "none" }}
                  onChange={() =>
                    uploadAvatarImage(
                      user,
                      user.displayName,
                      avatarFile.current.files[0]
                    )
                  }
                />
                <img
                  src={userDetails?.avatar}
                  alt="avatar"
                  className="avatar"
                  onClick={() => avatarFile.current.click()}
                />
              </div>
            }
          />
          {user && userDetails && (
            <div>
              <h4>{user.displayName}</h4>
              <p>
                {userDetails.role === "admin"
                  ? "Администратор"
                  : userDetails.role === "teacher"
                  ? "Учител"
                  : userDetails.role === "parent"
                  ? "Родител"
                  : userDetails.role === "buisness"
                  ? "Бизнес представител"
                  : userDetails.role === "student"
                  ? "Ученик"
                  : null}
              </p>
              {profilePosts && <p>{profilePosts.length} публикации</p>}
            </div>
          )}
        </Grid.Column>
        {profilePosts && profilePosts.length > 0 ? (
          <Grid.Column className="userPosts" stackable="true" width={10}>
            <h3>Публикации:</h3>
            <Grid columns={2} stackable>
              {profilePosts.map((post) => (
                <ProfilePost
                  key={post.postId}
                  post={post}
                  deletePostFromDB={deletePostFromDB}
                  user={user}
                />
              ))}
            </Grid>
          </Grid.Column>
        ) : (
          !profilePosts.length && <h3>Нямате публикации.</h3>
        )}
      </Grid>
    </Container>
  );
}

export default Profile;
